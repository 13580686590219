import {ICardApi} from "../api";

export const icardHomeData = async () => {
  return await ICardApi.loadHomeData();
}

export const loadCardDetail = async ({question}) => {
  const cardid = await ICardApi.findCardID(question)
  return await ICardApi.getCardDetail(cardid)
}

export const loadCardAnnualFee = async ({question}) => {
  const cardid = await ICardApi.findCardID(question)
  return await ICardApi.getAnnualFee(cardid)
}

export default [{
  "type": "function",
  "function": {
    "name": "icardHomeData",
    "description": "获取iCard网站首页的数据内容。"
  }
}, {
  "type": "function",
  "function": {
    "name": "loadCardDetail",
    "description": `
      获取iCard网站上指定某一张信用卡的详情信息。
      
      当你无法获取更多信用卡的信息时，调用该工具，可以获取更多的信用卡信息。请从与用户的对话中提取用户想要搜索的信用卡名称作为 question 参数的值。
    `,
    "parameters": {
      "type": "object",
      "required": ["question"],
      "properties": {
        "question": {
          "type": "string",
          "description": "需要获取信用卡详细信息的卡片名称，请从用户的提问或聊天上下文中提取。通常卡片名称都是iCard网站上返回的数据。"
        }
      }
    }
  }
}, {
  "type": "function",
  "function": {
    "name": "loadCardAnnualFee",
    "description": `
      获取iCard网站上指定某一张信用卡的年费信息。
      
      当你无法获取更多信用卡的年费信息时，调用该工具，可以获取更多的信用卡年费信息。请从与用户的对话中提取用户想要搜索的信用卡名称作为 question 参数的值。
    `,
    "parameters": {
      "type": "object",
      "required": ["question"],
      "properties": {
        "question": {
          "type": "string",
          "description": "需要获取信用卡详细信息的卡片名称，请从用户的提问或聊天上下文中提取。通常卡片名称都是iCard网站上返回的数据。"
        }
      }
    }
  }
}]