import {FILE_PATH} from "./constants";
import {Toast} from 'antd-mobile';

export function desensitizeMobile(mobile) {
  return mobile && mobile.replace(/(\d{3})\d*(\d{4})/, "$1****$2")
}

export function isWechat() {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf('micromessenger') > -1;
}

export function isIOS() {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf('iphone') > -1;
}

export function isAndroid() {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf('android') > -1;
}

export function isUnsupportScheme() {
  const {userAgent} = window.navigator;
  const ua = userAgent.toLowerCase();
  return /^.*futunn.*$/.test(ua) && isIOS()
}

export function success(text, time = 2, afterClose = () => {
}) {
  Toast.show({content: text, duration: time * 1000, icon: 'success', afterClose})
}


export function error(text, time = 2, afterClose = () => {
}) {
  Toast.show({content: text, duration: time * 1000, icon: 'fail', afterClose})
}

// 吐司提示
export function toast(text, time = 2, afterClose = () => {
}) {
  Toast.show({content: text, duration: time * 1000, afterClose});
}

// loading
export function loading(text = 'loading') {
  Toast.show({content: text, duration: 0, maskClickable: false, icon: 'loading'})
}

// 隐藏所有toast
export function clearToast() {
  Toast.clear();
}


export async function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function isFileHash(hash) {
  return /^[0-9a-f]{32}#\w{3,4}:\d+$/.test(hash)
}

export function fileHashToPath(hash) {
  if (/^[0-9a-f]{32}#\w{3,4}:\d+$/.test(hash)) {
    const value = hash.substring(0, hash.indexOf(':'));
    const values = value.split("#");
    const result = [
      FILE_PATH,
      values[0].substring(0, 1),
      values[0].substring(1, 3),
      values[0].substring(3)
    ].join('/');
    return values.length > 1 ? `${result}.${values[1]}` : result;
  }
  return hash;
}

export function dataURLtoFile(dataurl, filename) {
  let arr = dataurl.split(",");
  let mime = arr[0].match(/:(.*?);/)[1];
  let bstr = atob(arr[1]);
  let n = bstr.length;
  let u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, {type: mime});
}

/**
 * 计算以contain方式将图片画在canvas中的具体x,y,w,h
 * @param cw canvas.width
 * @param ch canvas.height
 * @param iw image.width
 * @param ih image.height
 * @return {x,y,w,h}
 */
export function containRectangle({cw, ch, iw, ih}) {
  let x, y, w, h;
  const imgRatio = iw / ih, canvasRatio = cw / ch;
  if (imgRatio <= canvasRatio) {
    h = ch
    w = imgRatio * h
    x = (cw - w) / 2
    y = 0
  } else {
    w = cw
    h = w / imgRatio
    x = 0
    y = (ch - h) / 2
  }
  return {x, y, w, h}
}

export function coverRectangle({cw, ch, iw, ih}) {
  let x, y, w, h;
  const imgRatio = iw / ih, canvasRatio = cw / ch;
  if (imgRatio <= canvasRatio) {
    w = cw
    h = w / imgRatio
    x = 0
    y = (ch - h) / 2
  } else {
    h = ch
    w = imgRatio * h
    x = (cw - w) / 2
    y = 0
  }
  return {x, y, w, h}
}

export async function wechatJsapiPayment(datas) {
  return new Promise((resolve, reject) => {
    if (typeof WeixinJSBridge === 'undefined') {
      reject('WeixinJSBridge is not found')
    } else {
      WeixinJSBridge.invoke('getBrandWCPayRequest', datas, res => {
        if (res.err_msg === "get_brand_wcpay_request:ok") {
          resolve(res)
        } else {
          reject(res)
        }
      });
    }
  })
}

export class BusiError extends Error {
  constructor(text, message) {
    super(message || text);
    this.text = text;
  }
}

export const movingAverage = (dayCount, datas) => {
  const result = [];
  for (let i = 0; i < datas.length; i++) {
    if (i < dayCount) {
      result.push('-');
      continue;
    }
    let sum = 0;
    for (let j = 0; j < dayCount; j++) {
      sum += datas[i - j];
    }
    result.push(+(sum / dayCount).toFixed(3));
  }
  return result;
}

export const formatPeriod = (period) => {
  if (/^\d{4}\s?Q\d$/.test(period)) {
    const fields = period.split('Q');
    return `Q${fields[1].trim()} ${fields[0].trim()}`
  }
  return period;
}