import {extend} from 'umi-request';
import {SPEECH_PATH, SPEECH_APP_KEY} from "../utils/constants";
import {speechStore} from '../stores';
import request from "../utils/request";
import qs from 'query-string';

const aliyuncs = extend({
  charset: 'utf-8',
  prefix: SPEECH_PATH,
})

export const getToken = async () => {
  const response = await request.get('/speech/token')
  return response.body
}

export const recognize = async (blob) => {
  const qyery = qs.stringify({
    format: 'wav',
    appkey: SPEECH_APP_KEY
  })
  return await aliyuncs.post(`/asr?${qyery}`, {
    requestType: 'blob',
    headers: {
      'X-NLS-Token': speechStore.token,
      'Host': 'nls-gateway.aliyuncs.com',
      'Content-type': 'application/octet-stream',
      'Content-Length': blob.size
    },
    body: blob
  })
}

export const synthesize = async (text) => {
  return await aliyuncs.post('/tts', {
    responseType: 'blob',
    data: {
      text, appkey: SPEECH_APP_KEY, token: speechStore.token, format: "mp3", voice: "qingqing"
    }
  })
}